<template>
	<ValidationProvider :rules="rules" :name="field" v-slot="{ errors }">

    <label class="flex items-center cursor-pointer">
      <t-checkbox
	        :variant="errors.length > 0 ? 'error' : ''"
	        name="options" @change="onChange()"
	        v-model="isChecked"
      />
	    <div v-if="labelTo">
		    <span class="ml-2 text-s inline hover:opacity-70 hover:text-redAccentLight">
          {{ label }}
        </span>
		    <router-link class="text-s inline hover:opacity-70 hover:text-redAccentLight underline"
          :to="labelTo" :class="{'text-badgeError': errors.length > 0}"
        >
			    {{ labelLink }}
		    </router-link>
	    </div>
	    <div v-if="labelAsClick">
		    <span class="ml-2 text-s inline hover:opacity-70 hover:text-redAccentLight">
          {{ label }}
        </span>
		    <span class="text-s inline hover:opacity-70 hover:text-redAccentLight underline"
               @click.stop="$emit('labelClick')" :class="{'text-badgeError': errors.length > 0}"
		    >
			    {{ labelLink }}
		    </span>
	    </div>
      <span v-else
        class="ml-2 hover:text-redAccentLight hover:opacity-70"
        :class="{'text-base': textBase, 'text-s': !textBase, 'text-badgeError': errors.length > 0}"
      >
        {{ label }}
      </span>
    </label>
	</ValidationProvider>
</template>
<script>

import BaseError from "@/utils/validation/components/BaseError.vue";

export default {
  name: 'CheckboxField',
	components: {BaseError},
  props: {
    value: [String, Object, Number, Boolean, Array],
    label: [String],
    labelLink: [String],
    textBase: Boolean,
    labelTo: Object,
	  labelAsClick: Boolean,
	  field: String,
	  rules: String,
  },
  data () {
    return {
      isChecked: false,
    }
  },
  methods: {
    onChange () {
      this.isChecked = this.isChecked ? 1 : 0
      this.$emit('input', this.isChecked)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.isChecked = val
      },
    },
  },
}
</script>
