<template>
    <PageContainer>
      <PageTitle class="w-full lg:w-form-auth-default">
        {{ trans('FORM_TITLE:Register') }}
      </PageTitle>
      <t-card variant="auth">
        <!-- register form -->
        <div>
          <ValidationObserver ref="registerForm" v-slot="{ invalid }">
            <form
                @submit.prevent="handleFormSubmit"
            >
              <div class="flex flex-col gap-y-3">
                <FieldWrapper>
                  <TextField
                      field="email"
                      autocomplete
                      :placeholder="trans('LABEL:EmailLogin')"
                      rules="email|required|max:255"
                      :custom-error="customError"
                      v-model="user.email"
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                      field="name"
                      name="name"
                      rules="required|min:8|max:50"
                      v-model="user.name"
                      :placeholder="trans('LABEL:DisplayedName')"
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                      isPassword
                      field="password"
                      rules="required|min:8|max:50"
                      v-model="user.password"
                      :placeholder="trans('LABEL:PasswordPlaceholder')"
                  />
                </FieldWrapper>
                <FieldWrapper noMargin>
                  <TextField
                      isPassword
                      field="password_confirmation"
                      rules="required|confirmed:password"
                      v-model="user.password_confirmation"
                      :placeholder="trans('LABEL:PasswordConfirm')"
                  />
                </FieldWrapper>
                <Hint variant="Info">
                  {{ trans('LABEL:RegisterYearOfBirthAndGenderComment-1') + ' ' + trans('LABEL:RegisterYearOfBirthAndGenderComment-2') }}
                </Hint>
                <div class="w-full flex flex-col gap-3 lg:flex-row">
                  <FieldWrapper class="w-full lg:flex-column lg:w-1/2">
                    <SelectField
                        v-model="user.gender_declared_id"
                        rules="required"
                        field="gender_declared_id"
                        :placeholder="trans('LABEL:GenderDeclared')"
                        :options="genderDeclaredData"
                    />
                  </FieldWrapper>
                  <FieldWrapper class="w-full lg:flex-column lg:w-1/2">
                    <SelectField
                        v-model="user.gender_formal_id"
                        rules="required"
                        field="gender_formal_id"
                        :placeholder="trans('LABEL:GenderFormal')"
                        :options="langData.genderTypes"
                    />
                  </FieldWrapper>
                </div>
                <div class="flex flex-col w-full lg:flex-row lg:space-x-3">
                  <FieldWrapper v-if="!isMobile" class="w-full lg:flex-column lg:w-1/2">
                    <!-- Empty cell -->
                  </FieldWrapper>
                  <FieldWrapper class="w-full lg:flex-column lg:w-1/2">
                    <SelectField
                        v-model="user.year_of_birth"
                        rules="required"
                        field="year_of_birth"
                        :placeholder="trans('LABEL:YearOfBirth')"
                        :options="yearsData"
                    />
                  </FieldWrapper>
                </div>
              </div>
              <div class="flex items-center justify-start mt-3">
                <CheckboxField
                    field="regulations_accepted_at"
                    v-model="user.regulations_accepted_at"
                    :label="trans('LABEL:RulesConsent1')"
                    :label-as-click="true"
                    @labelClick="openRegulationsModal(true)"
                    :label-link="trans('LABEL:RulesConsent2')"
                />
              </div>
              <div class="mt-3 text-s flex flex-row items-center justify-end">
	              <Button
                  variant="buttonRed"
                  type="submit"
                  :disabled="invalid"
                  :loading="registerLoading"
                  :text="trans('BUTTON:RegisterMe')"
	              />
              </div>
              <div class="text-s leading-tight flex flex-row items-center justify-start">
                <div>
                  {{ trans('TEXT:AlreadyRegistered') }}
                  <Button
                    :to="{name: 'auth-login'}"
                    variant="underline"
                    :text="trans('BUTTON:LogIn!')"
                  />
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>

      </t-card>
	    <Modal
			    v-if="isShowRegulationsModal"
			    :show-modal="isShowRegulationsModal"
			    @clicked="openRegulationsModal(false)"
			    variant="basic"
			    hide-footer
	    >
        <template v-slot:header>
          {{trans("PAGE_TITLE:Rules")}}
        </template>
		    <template v-slot:content>
			    <div class="flex flex-row items-center mb-4">
				    <span v-html="postData.body" class="w-full leading-snug ">
				    </span>
			    </div>
			    <div class="flex flex-row justify-end">
				    <Button
						    :text="trans('BUTTON:RulesAccepted')"
						    @clicked="openRegulationsModal(false)"
				    >
				    </Button>
			    </div>
		    </template>
	    </Modal>
    </PageContainer>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import Button from "@/utils/crud/components/Button";
import TextField from "@/utils/crud/components/field-types/Text";
import SelectField from "@/utils/crud/components/field-types/Select";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import CheckboxField from "@/utils/crud/components/field-types/Checkbox.vue";
import Modal from "@/utils/modal/Modal.vue";
import Hint from "@/views/content/components/Hint"
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

const generateYearsBetween = function (min, max) {
  const endDate = new Date();
  const startDate = new Date();
  endDate.setFullYear(endDate.getFullYear() - min)
  startDate.setFullYear(endDate.getFullYear() - max)
  let years = [];
  let start = startDate.getFullYear()
  let end = endDate.getFullYear()
  for (let i = end; i >= start; i--) {
    years.push(end);
    end--;
  }
  return years;
}

export default {
  components: {Modal, CheckboxField, PageTitle, FieldWrapper, TextField, Button, SelectField, PageContainer, Hint},

  data () {
    return {
      isMobile: checkIsMobileDevice(),
      user: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
	      gender_declared_id: '',
        year_of_birth: '',
        regulations_accepted_at: false,
      },
      yearsData: null,
      customError: null,
	    isShowRegulationsModal: false,
    }
  },
  computed: {
    ...mapState('account', ['status']),
    ...mapGetters('dictionaries', ['genderData', 'categoriesData', 'genderDeclaredData']),
    ...mapGetters('lang', ['langLocale', 'configData']),
    ...mapGetters('account', ['errors', 'registerLoading']),
	  ...mapGetters('posts', ['postData']),

    getRegulationsRoute() {
      let category = this.categoriesData.find(el => el.slug === 'rules')
      return {name: 'post-show', params: {category: category.slug, slug: category.onlyPostSlug}}
    },
  },
  watch: {
    langLocale() {
      this.fetchGenderDeclaredData();
    },
    errors(val) {
      if(val.hasOwnProperty('email')) {
        this.customError = 'EMAIL';
      }
      this.$refs.registerForm.setErrors(val);
    },
  },
  methods: {
    ...mapActions('account', ['register']),
    ...mapActions('dictionaries', ['fetchGenderDeclaredData']),
	  ...mapActions('posts', ['fetchPostShowRequest', 'fetchPostSiteRulesRequest']),

    handleFormSubmit(e) {
      const isFormValid = this.$refs.registerForm.validate()
      if (!isFormValid) return
	    this.user.lang = this.langLocale;

      this.register(this.user);
    },

	  openRegulationsModal(value) {
			this.isShowRegulationsModal = value;
	  }

  },
  mounted() {
    this.yearsData = generateYearsBetween(18, 100);
	  this.fetchPostSiteRulesRequest();

  },
  setup() {

  }
}
</script>

<style lang="scss" scoped>
</style>
